<template>
  <div class="w-full">
    <div class="SliderContainer flex items-center">
      <div class="Container relative w-full">
        <button
          class="swiper-nav swiper-nav-prev relative"
          :class="{
            'swiper-nav__inverted': inverted,
          }"
          aria-label="previous slide"
          @click="prev"
        >
          <svg-icon name="chveron-left" class="text-primary-800 fill-current h-4 w-3" />
        </button>
        <Swiper
          :modules="[SwiperNavigation, SwiperEffectFade]"
          :slides-per-view="props.itemsPerRowOnMobile"
          :space-between="15"
          :slides-offset-before="25"
          :slides-offset-after="25"
          class="pb-20"
          :breakpoints="{
            '350': {
              slidesPerView: 1.5,
              spaceBetween: 15,
              centeredSlidesBounds: true,
              centerInsufficientSlides: true,
              slidesOffsetBefore: 25,
              slidesOffsetAfter: 25,
            },
            '768': {
              slidesPerView: 3,
              spaceBetween: 26,
              centeredSlidesBounds: true,
              centerInsufficientSlides: true,
              slidesOffsetBefore: 25,
              slidesOffsetAfter: 25,
            },
            '1024': {
              slidesPerView: 5,
              spaceBetween: 26,
              centeredSlidesBounds: false,
              centerInsufficientSlides: false,
              slidesPerGroupAuto: false,
              slidesOffsetBefore: 0,
              slidesOffsetAfter: 30,
            },
          }"
        >
          <SwiperSlide v-for="item in items" :key="item.id" class="swiper-slide">
            <div class="product-item">
              <slot :item="item" />
            </div>
          </SwiperSlide>

          <SwiperControls :current-index="currentIndex" @change="($event: number) => (currentIndex = $event)" />
        </Swiper>
        <button
          class="swiper-nav swiper-nav-next relative"
          :class="{ 'swiper-nav__inverted': inverted }"
          aria-label="next slide"
          @click="next"
        >
          <svg-icon name="chveron-right" class="text-primary-800 fill-current h-4 w-3" />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  items: {
    type: Array as PropType<ProductNode[]>,
    required: true,
  },
  arrows: {
    type: Boolean,
    default: false,
  },
  itemsPerRowOnDesktop: {
    type: Number,
    default: 5,
  },
  itemsPerRowOnMobile: {
    type: Number,
    default: 2.4,
  },
  alignment: {
    type: String,
    default: 'start',
    validator: (value: string) => ['start', 'center', 'end'].includes(value),
  },
  inverted: {
    type: Boolean,
    default: false,
  },
});
const currentIndex = ref(0);

const next = () => {
  currentIndex.value += 1;
};

const prev = () => {
  currentIndex.value -= 1;
};
</script>

<style lang="postcss" scoped>
.swiper,
swiper-container {
  --spread-value: 55px;
  height: calc(435px + (2 * var(--spread-value)));
  padding-bottom: calc(var(--spread-value));
  margin-bottom: calc(-1 * var(--spread-value));
  padding-top: calc(var(--spread-value));
  margin-top: calc(-1 * var(--spread-value));

  @screen lg {
    padding-left: 20px;
    margin-left: -20px;
  }
}

.swiper-slide {
  flex-shrink: 1;
}

.product-item {
  height: 100%;
}

@screen md {
  .product-item {
    height: 100%;
  }
}

.swiper-nav {
  @apply hidden lg:flex rounded-full border border-primary-800 absolute top-1/2 transform -translate-y-1/2;
  width: 145px;
  height: 145px;

  &-prev {
    left: -175px;
    svg {
      @apply text-primary-800 absolute top-1/2;
      height: 26px;
      --border-line: clamp(20px, calc(100vw - 320px - 1180px), 70px);
      transform: translate(50%, -50%);
      right: var(--border-line);
    }
  }

  &-next {
    right: -175px;

    svg {
      @apply text-primary-800 absolute top-1/2;
      height: 26px;
      --border-line: clamp(20px, calc(100vw - 320px - 1180px), 70px); /*clamp(0px, -20px, -20px);*/
      transform: translate(-50%, -50%);
      left: var(--border-line);
    }
  }

  &__inverted {
    @apply text-primary-800;

    svg {
      @apply text-primary-800;
    }
  }

  &.swiper-button-disabled {
    opacity: 0.3;
  }
}

.swiper-container-horizontal > .swiper-scrollbar {
  position: absolute;
  bottom: 3px;
  left: 5%;
  z-index: 50;
  height: 2px;
  width: 98%;

  @media (min-width: 1024px) {
    width: 100%;
    left: 0;
  }
}

.Container {
  @apply mx-auto;
  max-width: 1366px;
}

.SliderContainer {
  @apply relative  mx-auto;
  max-width: 1800px;
  overflow-x: clip;
}
</style>
